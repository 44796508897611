import Container from 'components/shared/Container';
import React from 'react';
import illustration from 'assets/icons/ilustracja5.svg';
import { IllustrationWrapper } from './ConfirmationIllustration.styled';

const ConfirmationIllustration = () => {
   return (
      <Container>
         <IllustrationWrapper>
            <img src={illustration} alt="illustration" />
         </IllustrationWrapper>
      </Container>
   );
};

export default ConfirmationIllustration;
