import ConfirmationIllustration from 'components/pages/confirmation/ConfirmationIllustration';
import SubHeader from 'components/pages/confirmation/SubHeader';
import PageHeader from 'components/shared/PageHeader';
import SEO from 'components/shared/SEO';
import React from 'react';

const Confirmation = () => {
   const headerText = ['Dziękujemy za ', <strong>wiadomość</strong>];
   const paragraph =
      'Twoja wiadomość została pomyślnie wysłana. Wkrótce skontaktujemy się z Tobą a w między czasie zapraszamy do zapoznania się z historią budowy produktu';

   return (
      <>
         <SEO title="Sukces!" />
         <PageHeader headerText={headerText}>
            <SubHeader paragraph={paragraph} />
         </PageHeader>
         <ConfirmationIllustration />
      </>
   );
};

export default Confirmation;
