import styled from 'styled-components';
import { breakpoints } from 'styles/variables';

export const IllustrationWrapper = styled.div`
   margin-top: 50px;
   padding: 0 15px;
   img {
      max-width: 100%;
   }
   ${breakpoints.lg`
      margin-top: 100px
   `}
`;
