import styled from 'styled-components';
import { breakpoints } from 'styles/variables';

export const SubheaderParagraph = styled.p`
   max-width: 630px;
   margin: 25px auto 40px;
   text-align: center;
   line-height: 2.8rem;
`;

export const ButtonsContainer = styled.div`
   a {
      display: flex;
      flex-flow: column wrap;
      margin-right: 7px;
      ${breakpoints.lg`
         margin-right: 15px;
      `}
   }
   display: flex;
   justify-content: center;
   margin-top: 30px;
   align-items: flex-start;
`;
